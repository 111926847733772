<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.projects") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="projects"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="createdAt"
        :ascending="false"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/projects/${id}`)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/projects/create')"
              :icon="PlusIcon"
              id="header-project-create-button"
              label="create_project"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(requestId)="{ value, item }">
          <div v-if="value">
            <router-link
              :to="`/notifications/${value}`"
              class="underline hover:text-blue-400"
              >{{
                item.notification && item.notification.estate
                  ? item.notification.estate.name +
                    " " +
                    item.notification.apartment
                  : null
              }}</router-link
            >
          </div>
        </template>
        <template #cell(estateId)="{ value }">
          {{ getEstateName(value, estates) }}
        </template>
        <template #cell(createdAt)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(status)="{ value }">
          {{ t("status." + value) }}
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getEstateName } from "../utils/estateUtil";

export default {
  components: { Breadcrumb, CustomTable, FormButton, PlusIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "requestId",
        label: "request",
      },
      {
        key: "estateId",
        label: "estate_name",
      },
      {
        key: "createdAt",
        label: "created_at",
      },
      {
        key: "status",
        label: "status",
      },
    ]);
    const searchFields = ref(["name", "createdAt", "status"]);
    const sortFields = ref(["name", "createdAt", "status"]);

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const projects = computed(() => store.state.project.projects);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.project.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    onMounted(() => {
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", company.value.id);
      }
      if (!projects.value.length) {
        store.dispatch("project/getProjects", user.value.companyId);
      }
    });

    return {
      company,
      estates,
      fields,
      getEstateName,
      getLocaleDate,
      locale,
      projects,
      router,
      PlusIcon,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
